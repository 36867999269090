
@import "../../../global.less";
@primary-color:#00be86;
.container-confirm{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .content-confirm{
        display: flex;
        flex-direction: column;
        align-items: center;
        .container-circle-icon{
            background-color: @aion-color-Platinum;
            width: 90px;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-bottom: 15px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
            svg{
                height: 55px;
                width: 55px;
                path{
                    fill:@primary-color;
                }
            }
        }
        .container-title{
            margin-bottom: 15px;
            font-size: @aion-font-size-3x;
            color: @aion-color-gray;
        }
        .container-description{
            text-align: center;
            font-size: @aion-font-size-lg;
            color: @aion-color-dark-silver;
        }
    }
}


