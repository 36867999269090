@import url("../../../global.less");

.wrapper-pricing-cards {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    height: 100%;
    padding: 0 15px;
    justify-content: center;
    align-items: center;
    .package {
      box-sizing: border-box;
      width: 330px;
      min-height:350px;
      border: 3px solid @aion-color-light-gray;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      padding: 24px;
      text-align: center;
      align-items: center;
      transition: all 0.2s linear;
      position: relative;
      &:hover:not(&.disabled) {
        transform: scale(1.01);
        border-color: @aion-color-blue;
      }
      &.disabled{
        border-color: @aion-color-light-gray;
        cursor: not-allowed;
        transition: none;
        button{
          cursor: not-allowed !important;
          background-color: @aion-color-light-gray !important;
        }
      }
      .name {
        color:  @aion-color-dark-silver;
        font-weight: 300;
        font-size: 3rem;
        margin-top: -5px;
      }
      .price {
        margin-top: 7px;
        font-weight: bold;
        color: @aion-color-light-gray;
      }
      hr {
        background-color:@aion-color-blue;
        border: none;
        height: 1.7px;
        width: 100%;
      }
      .trial {
        font-size: 0.9rem;
        font-weight: 600;
        padding: 2px 21px 2px 21px;
        color: @aion-color-dark-silver;
        border: 1px solid @aion-color-blue;
        display: inline-block;
        border-radius: 15px;
        background-color: @aion-color-white;
        position: relative;
        bottom: -20px;
      }
      .content-card-description {
        text-align: left;
        margin-top: 29px;
        height: 100%;
        opacity: 0.6;
        flex:1;
      }
      .container-button{
        display: flex;
        align-items: center;
        justify-content: center;
        button,button:is(:focus,:active,:hover){
          background-color: @aion-color-blue;
          color: @aion-color-white;
          border: none;
          outline: none;
          padding: 10px 20px;
          border-radius: 8px;
          height: auto;
          cursor: pointer;
        }

      }
    }

}
