@import "../../global.less";

.container-header{
  width: 100%;
  h1{
    text-align: center;
    font-size: @aion-font-size-6x;
    font-weight: @aion-font-weight-bolder;
    color: @aion-color-gray;
  }
}
.container-intents {
  display: flex;  
  flex-wrap: wrap;
  padding: 8px;
  max-width: 1300px;
  gap: 20px 10px;
  max-height: min(calc(100vh - 100px), 800px);
  overflow: auto;

  > div:not(.container-header) {
    flex: auto;
    min-width: 400px;
    min-height: 300px;
    max-width: 400px;
    box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0 0 0 1px;

    > div {
      margin-left: 8px;
    }
    .message-card-title {
      font-size: @aion-font-size-2x !important;
      font-weight: @aion-font-weight-bold !important;
    }
    .description-message-card {
      margin-left: 8px;
    }
    &:hover {
      box-shadow: @aion-color-blue 0 1px 8px 1px;
    }
  }
}
.header-intent {
  color: @aion-color-blue;
  padding-bottom: 10px;
}

.container-alert-register {
  min-width: 250px;
  margin-bottom: 25px;
}

.container-details-intent {
  .page-title {
    font-size: @aion-font-size-6x;
    font-weight: @aion-font-weight-bolder;
  }
  .header-lbl,
  .ant-form-item-label label {
    font-size: 18px;
    font-weight: @aion-font-weight-bold;
  }
  .description-text,
  .message-area {
    font-size: 16px;
    &.cls-input-TextArea-primary{
    border: 1px @aion-color-blue solid;
    }
    &.paragraph{
      color: @aion-color-dark-silver;
    }
  }
  .container-btn-save-intent > button > span {
    font-size: 16px;
  }
}

.drawer-container{
  .ant-drawer-content-wrapper{
    width:400px  !important;
    min-width: 400px !important;
  }
}

.container-message-card {
  .description-message-card{
    margin-bottom: 50px;
  }
  .tags{
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
}

.container-message-card.load-more-card{
  display: flex;
  justify-content: center;
  align-items: center;

  .container-header-message-card{
    display: none;
  }
  .description-message-card{
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 50px;
    svg{
      height: 60px;
      width: 60px;
      path{
        color:@aion-color-dark-silver
      }
    }
  }
  
}

.intent-checkbox{
  margin-bottom: 18px;
}
