// UI Kit Color
@aion-color-red: #FF306C;
@aion-color-white: #FFFFFF;
@aion-color-gray: #414042;
@aion-color-blue: #3BACE2;
@aion-color-dark-blue: #0071f2;
@aion-color-Platinum: #f7f7f7;
@aion-color-semi-dark: #666666;
@aion-color-dark-silver: #6D6E71;
@aion-color-light-gray: #D1D3D4;
@aion-color-black: #000;









/*** Fonts Size ***/
@aion-font-size-sm: 12px;
@aion-font-size-md: 14px;
@aion-font-size-lg: 16px;
@aion-font-size-xl: 18px;
@aion-font-size-2x: 20px;
@aion-font-size-3x: 22px;
@aion-font-size-4x: 24px;
@aion-font-size-5x: 26px;
@aion-font-size-6x: 35px;
@aion-font-size-7x: 40px;

/*** Fonts Weight ***/
@aion-font-weight-light: 300;
@aion-font-weight-normal: 400;
@aion-font-weight-bold: 500;
@aion-font-weight-bolder: 600;
@aion-font-weight-bolder-lg:700;


/**** Line Height ****/
@aion-line-height-sm: 16px;
@aion-line-height-md: 20px;
@aion-line-height-lg: 24px;
@aion-line-height-xl: 28px;
@aion-line-height-4x: 45px;

/*** font-family ***/
@aion-font-inter-family: 'Inter-Regular', sans-serif;
@aion-font-inter-family-medium: 'Inter-Medium', sans-serif;
@aion-font-inter-family-bold: 'Inter-Bold', sans-serif;
@aion-font-inter-family-semi-bold: 'Inter-SemiBold', sans-serif;


@aion-font-montserrat-family: 'Montserrat-Regular', sans-serif;
@aion-font-montserrat-family-medium: 'Montserrat-Medium', sans-serif;
@aion-font-montserrat-family-semi-bold: 'Montserrat-SemiBold', sans-serif;

@aion-font-saira-family-regular: 'Saira-Regular', sans-serif;
@aion-font-saira-family-regular:'Saira-Regular', sans-serif;

/*** Box Shadow ***/
@aion-box-shadow-primary: 0 0 10px rgb(0 0 0 / 10%);
@aion-box-shadow-secondary: rgba(100, 100, 111, 0.2) 0 0 8px 0;
@aion-box-shadow-tertiary: 0 0 10px rgb(0 0 0 / 10%);
@aion-box-shadow-quaternary: 0 0 10px rgb(0 0 0 / 10%);
@aion-box-shadow-card: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
/*** Border Radius ***/
@aion-border-radius-xm: 4px;
@aion-border-radius-sm: 6px;
@aion-border-radius-md: 8px;
@aion-border-radius-lg: 40px;
@aion-border-radius-round: 50%;

/*** height Inputs ***/
@aion-input-height-sm: 42px;
@aion-input-height-md: 50px;
@aion-input-height-lg: 66px;



/*** style Font Function ***/
.aion-inter-regular(@size,@line-height) {
    font-family: @aion-font-inter-family;
    font-size: @size;
    line-height: @line-height;
}
.aion-inter-medium(@size,@line-height) {
    font-family: @aion-font-inter-family-medium;
    font-size: @size;
    line-height: @line-height;
}
.aion-inter-semi-bold(@size,@line-height) {
    font-family: @aion-font-inter-family-bold;
    font-size: @size;
    line-height: @line-height;
}

.aion-montserrat-regular(@size,@line-height) {
    font-family: @aion-font-montserrat-family;
    font-size: @size;
    line-height: @line-height;
}
.aion-montserrat-medium(@size,@line-height) {
    font-family: @aion-font-montserrat-family-medium;
    font-size: @size;
    line-height: @line-height;
}
.aion-montserrat-semi-bold(@size,@line-height) {
    font-family: @aion-font-montserrat-family-semi-bold;
    font-size: @size;
    line-height: @line-height;
}

.aion-saira-regular(@size,@line-height) {
    font-family: @aion-font-saira-family-regular;
    font-size: @size;
    line-height: @line-height;
}

.aion-saira-medium(@size,@line-height) {
    font-family: @aion-font-saira-family-medium;
    font-size: @size;
    line-height: @line-height;
}


