@import url("../../../global.less");

.custom-button-red {
  background-color: @aion-color-dark-blue;
  color: @aion-color-white;
  height: @aion-input-height-sm;
  border: none;
  &:hover,
  &:focus,
  &:active {
    background-color: @aion-color-dark-blue;
    color: @aion-color-white;
    border: none;
  }
}
button.ant-btn,button.ant-btn >span{
    .aion-montserrat-medium(@aion-font-size-md,@aion-line-height-md) 
}

.ant-btn.ant-btn-ghost{
    color: @aion-color-blue;
    border-color: @aion-color-blue;
}
