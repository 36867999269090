@import "../../../global.less";


.btn-color-input{
    min-height:40px;
    min-width: 40px;
    cursor: pointer;
    border-radius: @aion-border-radius-md;
    display: block;
    box-shadow: @aion-color-blue 0 0 0 1px;
    input{
        visibility: hidden;
    }
}
