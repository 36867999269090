@import "../../../global.less";
.custom-modal {
  .ant-modal-content {
    border-radius: @aion-border-radius-md;
    >button{
        .ant-modal-close-x svg {
            height: 20px;
            width: 20px;
            path {
              fill: @aion-color-gray;
            }
          }
    }

  }
  .ant-modal-header{
    .ant-modal-title{
        font-size: @aion-font-size-4x;
        font-weight: @aion-font-weight-bolder;

    }
  }
  .ant-modal-body{
    color: @aion-color-dark-silver;
  }
  .ant-modal-footer{
    .ant-btn.ant-btn-default{
       color: @aion-color-blue
    }
  }
}

