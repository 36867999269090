@import "../../../global.less";
@import '../../../global.less';
.full-width {
    min-width: 100%;
    height: 45px;
  }

  .custom-input-primary{
    border:  1px solid @aion-color-blue;
    width: 350px;
    box-shadow: none;
    .ant-picker-input{
        flex-direction: row-reverse;
        .ant-picker-suffix{
            margin-left: 0;
            margin-right: 6px;
            svg path{
                fill:@aion-color-blue;
            }
        }
    }
   
  }
