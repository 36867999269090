@import "../../global.less";
.container-chat-page {
  min-height: 70vh;
  max-height: 70vh;
  width: 100%;
  padding: 5px;
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  > div {
    flex: 1;
    &.container-details {
      max-width: 350px;
      background-color: @aion-color-Platinum;
      margin-right: 5px;
      border-radius: @aion-border-radius-md;
      >h2{
        font-size: @aion-font-size-3x;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: @aion-font-weight-bolder;
        color: @aion-color-blue;
      }
      ul{
        li{
          list-style: none;
          line-height:@aion-line-height-4x;
          display: flex;
          gap: 10px;
          margin: auto;
          width: 280px;
          >div{
            min-width: 110px;
            font-size: @aion-font-size-lg;
            font-weight: @aion-font-weight-bold;
            color: @aion-color-semi-dark !important;
            &:nth-child(2){
              font-weight: @aion-font-weight-normal;
              text-transform: capitalize;
            }
          }
        }
      }
    }
    &.container-list-chat {
      height: 70vh;
      max-width: 380px;
      display: flex;
      flex-direction: column;
      .header-filter{
        padding: 10px 5px;
        margin-bottom: 8px;
        border-radius: @aion-border-radius-md;
        background-color: @aion-color-Platinum;
        box-shadow: @aion-box-shadow-card;
        .filter-label{
          font-size: @aion-font-size-2x;
          color: @aion-color-blue;
          padding-bottom: 8px;
          display: block;
        }
        .ant-picker-range{
          height: 50px;
          border-radius: @aion-border-radius-md;
          
          input{
            color:@aion-color-gray;
            font-size:@aion-font-size-md;
          }
        }
      }
      .menu-list-chat {
        overflow-y: scroll;
        border: none;
        padding: 0;
        padding-top: 5px;
        background-color: @aion-color-Platinum;
        border-radius: @aion-border-radius-md;
        box-shadow: @aion-box-shadow-card;
        height: 100%;
        li {
          height: auto !important;
          padding-left: 0;
          padding-right: 0;
          background-color: transparent;
          .container-message-card {
            background-color: @aion-color-white;
            box-shadow: @aion-box-shadow-card;
            margin: 0 0 0 5px;
          }
          .new-message{
            background-color: #f3f3f3;
          }
          .container-header-message-card {
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
            .message-card-title {
              margin-top: 8px !important;
              .conversation-title {
                font-size: @aion-font-size-3x;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              }
              .conversation-email {
                font-size: @aion-font-size-md;
              }
            }
            .container-avatar {
              height: 55px;
              width: 55px;
              font-size: @aion-font-size-6x;
              font-weight: @aion-font-weight-bold;
              padding: 5px;
              line-height: @aion-line-height-4x;
            }
          }
          &:active {
            background: transparent;
          }
          &.ant-menu-item-active {
            .message-card-time {
              color: @aion-color-gray;
            }
          }
          &.ant-menu-item-selected {
            .container-message-card {
              border: solid @aion-color-blue 1px;
              .conversation-title,
              .conversation-email {
                color: @aion-color-blue;
              }
              .container-avatar {
                background-color: @aion-color-blue;
              }
            }
            background: @aion-color-white;
            .message-card-time {
              color: @aion-color-gray;
            }
          }
        }
      }
    }
    &.container-main-chat {
      background: rgba(0, 0, 0, 0.01);
      border-radius: @aion-border-radius-xm;
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-height: 70vh;
      box-shadow: @aion-box-shadow-card;
      .message-list {
        padding: 10px 10px 0 10px;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow-y: scroll;
        .container-message-card {
          cursor: unset;
          width: 80%;
          align-self: flex-start;
          padding: 15px;
          .message-card-title {
            color: @aion-color-blue;
            text-transform: capitalize;
          }
          &.secondary {
            align-self: flex-end;
          }
        }
      }
      .container-input-chat {
        display: flex;
        border: 1px solid @aion-color-blue;
        border-radius: 4px;
        margin: 0;
        padding: 0;
        input{
          border-radius: unset !important;
        }
        > div {
          flex: 1;
          .ant-form-item {
            margin: 0;
          }
        }
        > button {
          border-radius: 0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
.container-conversations {
  line-height: 21px;
  .conversation-title {
    font-size: @aion-font-size-xl;
    font-weight: @aion-font-weight-bold;
    color: @aion-color-gray;
    text-transform: capitalize;
    padding-right: 3px;
    display: block;
    .message-waiting{
      position: absolute;
      right: 10px;
      top: 5px;
    }
  }
  .conversation-email {
    font-size: @aion-font-size-sm;
  }
}
.page-message{
  width: 100%;
  height: 100%;
  
}
