@import "../../global.less";

.custom-said-bar-menu {
  background-color: @aion-color-blue;
  position: relative;
  .ant-space.ant-space-vertical,.ant-space.ant-space-horizontal.ant-space-align-center{
    padding: 16px 0px 20px !important;
  }
  .aion-chat-icon {
    display: none;
  }
  .aion-text-icon {
    display: block;
  }
  .ant-layout-sider-trigger {
    display: none;
  }
  .ant-space .ant-space-item {
    z-index: 10;
  }
  ul.ant-menu {
    background-color: transparent;
    li.ant-menu-item {
      justify-content: start;
      z-index: 2;
      display: flex;
      align-items: center;
      padding: 0 !important;
      svg {
        flex: 1;
        display: block;
        height: 26px;
        width: 26px;
      }
      .ant-menu-title-content {
        font-size: 15px;
        font-weight: 500;
        color: #fff;
        min-width: 140px;
      }
    }
  }
  
  &.ant-layout-sider-collapsed {
    max-width: 70px !important;
    min-width: 70px !important;
    background-color: @aion-color-gray;
    overflow: hidden;
    .aion-chat-icon {
      display: block;
    }
    .aion-text-icon {
      display: none;
    }
    &::after {
      content: unset;
    }
    li.ant-menu-item {
      display: flex !important;
      padding: 0;
      .ant-menu-title-content {
        display: none;
      }
    }
    
    .btn-none-style {
      float: right;
      .anticon-double-right {
        height: 20px;
        width: 20px;
      }
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-active {
    background-color: transparent;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 5px;
      width: 5px;
      height: 100px;
      background-color: @aion-color-blue;
    }
  }
}

.btn-none-style {
  border: none;
  background: transparent;
  padding-right: 5px;
  cursor: pointer;
  svg path {
    fill: @aion-color-white;
  }
}

aside .aion-logo-icon {
  > .aion-chat-icon {
    margin-left: 10px;
    height: 50px;
    width: 50px;
  }
  > .aion-text-icon {
    height: 40px;
    width: 160px;
    align-self: center;
  }
}

