@import "../../global.less";
.custom-input-prefix {
  .ant-input-prefix {
    .aion-message-icon ,.aion-lock-icon{
      height: 18px;
      width: 18px;
    }
  }
}
.custom-btn-login{
    font-weight: @aion-font-weight-bolder-lg;
}


.container-login{
    display: flex;
    height: 100vh;
    >div{
        flex: 1;
        &.chat-login{
            padding: 100px;
            height: 100%;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        &.container-background{
            background-image: url('../../../public/img/background/aion-login.svg') ;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: @aion-color-blue;
            // background 
        }
        
    }
}
.container-header-page{
    .aion-saira-regular(@aion-font-size-7x,@aion-line-height-md);
    font-weight: @aion-font-weight-bolder;
    text-align: center;
    color: @aion-color-gray;

    &.login{
        margin-bottom:65px;
    }
}

.custom-link-style{
    .aion-saira-regular(@aion-font-size-md,@aion-line-height-md);
    color: @aion-color-gray;
    &:hover{
        color: @aion-color-gray;
    }
    &.login{
        text-align: center;
        display: block;
        margin-top: 15px;
    }
}

.ant-layout-content{
    background-color: @aion-color-white !important;
    padding: 0 !important;
}

.min-width-md{
    min-width: 300px;
    margin-bottom:20px ;
    text-align: center;
}
