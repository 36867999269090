@import "../../../global.less";
.fc-timegrid-event {
    box-shadow: none !important;
    border-radius: @aion-border-radius-md;
    border: none;
    .fc-event-main {
      padding: 2px;
    }
  }
  .fc-header-toolbar {
    padding-left: 15px;
  }
  .fc-col-header-cell.fc-day {
    text-transform: uppercase;
    font-weight: @aion-font-weight-light;
  }
  
  .fc .fc-timegrid-col.fc-day-today {
    background-color: @aion-color-white !important;
  }
  
  .fc .fc-toolbar-title {
    font-weight: @aion-font-weight-normal;
    line-height: @aion-line-height-lg;
    font-size: @aion-font-size-lg !important;
    padding: 8px 0;
    margin: 0 10px !important;
    border-radius: @aion-border-radius-sm;
    box-shadow: @aion-box-shadow-secondary;
    text-transform: uppercase;
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cls-container-select{
    position: absolute;
    left: 355px;
    width: 152px;
    height: 40px;
    transform: translateY(-7px);
    .ant-select-selector{
      height: 45px;
    }
    .ant-select-selection-item{
      line-height: @aion-font-size-7x !important;    
      color: @aion-color-light-gray;
    }
   }
  
  
  .fc-toolbar-chunk >div{
  display: flex;
  flex-direction: row;
  .fc-button.fc-button-primary ,.fc .fc-toolbar-title .fc-button.fc-button-primary:active,
  .fc-button.fc-button-primary:focus,
  .fc-button.fc-button-primary:focus-visible,
  .fc-button.fc-button-primary:focus-within{
    background-color: @aion-color-white;
    color: @aion-color-black;
    font-size: @aion-font-size-sm ;
    padding: 8px;
    border: none;
    border-radius: @aion-border-radius-sm;
    box-shadow: @aion-box-shadow-secondary;
    margin-right: 5px;
    text-transform: capitalize;
    text-align: center;
    &.fc-prev-button,&.fc-next-button{
        height: 40px;
        width: 40px;
    }
  }
  }


  button.fc-ADD-button.fc-button.fc-button-primary,
  .btn-close-modal {
    background-color: @aion-color-red;
    font-size: @aion-font-size-lg !important;
    padding: 8px 15px;
    border: none;
    border-radius: @aion-border-radius-sm;
  }
  
  .fc-toolbar-title {
    display: inline;
  }
  
  .fc-timegrid-slots > table > tbody > tr td {
    font-size: @aion-font-size-sm;
    padding: 30px 10px;
    color: @aion-color-black;
    opacity: 0.5;
  }
  

  .fc-theme-standard td {
    padding: 2px 0;
    border: none;
    border-right: 1px solid rgba(190, 190, 190, 0.5);
    border-top: 1px solid rgba(190, 190, 190, 0.2);
  }
  .fc-theme-standard th {
    border-left: none;
    border-bottom: 1px solid rgba(190, 190, 190, 0.2);
    border-right: 1px solid rgba(190, 190, 190, 0.5);
  }
  .fc-timegrid-col.fc-timegrid-axis {
    border: none;
  }
  
  td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink,
  th.fc-timegrid-axis,
  table.fc-scrollgrid.fc-scrollgrid-liquid,
  .fc-theme-standard .fc-scrollgrid {
    border: none !important;
  }
  
button.fc-today-button{
    background-color: @aion-color-blue !important;
    color: @aion-color-white !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .eventInfo-timeText {
    font-weight: @aion-font-weight-normal;
    font-size: @aion-font-size-sm;
    line-height: @aion-line-height-sm;
  }
  
  .eventInfo-eventTitle {
    cursor: pointer;
    font-weight: @aion-font-weight-bolder;
    font-size: @aion-font-size-md;
    padding-left: 5px;
    line-height: @aion-line-height-md;
    margin: 5px 0 0 0;
    text-align: start;
  }

  
  a.fc-col-header-cell-cushion {
    font-weight: @aion-font-weight-bold;
    font-size: @aion-font-size-md;
    line-height: @aion-line-height-md;
    text-align: center;
    color: @aion-color-light-gray;
  }
  
  a.fc-col-header-cell-cushion b {
    color: @aion-color-black;
  }
  
  .fc-timegrid-body {
    z-index: 0 !important;
  }
  
  .cls-header-day-fullcalendar {
    color: @aion-color-gray;
    display: block;
  }

  .fc-col-header-cell {
    .fc-scrollgrid-sync-inner {
      height: 68px;
    }
    &.fc-resource .fc-scrollgrid-sync-inner {
      display: flex;
      flex-direction: column-reverse;
      .fc-col-header-cell-cushion {
        color: @aion-color-gray;
        font-weight: @aion-font-weight-normal;
        font-size: @aion-font-size-lg;
        line-height: @aion-line-height-md;
        margin-bottom: 5px;
      }
    }
  }
  
  .eventInfo-eventTitle,.eventInfo-timeText,.cls-container-list{
    margin-left: 4px;
    margin-right: 4px;
  }
  button.fc-timeGridWeek-button,button.fc-timeGridWeek-button:is(:active,:focus,:hover),
  button.fc-timeGridDay-button,button.fc-timeGridDay-button:is(:active,:focus,:hover),
  button.fc-dayGridMonth-button,button.fc-dayGridMonth-button:is(:active,:focus,:hover){
    background-color: @aion-color-dark-blue!important;
    color: @aion-color-white !important;
    border: none !important;
    border-radius: @aion-border-radius-sm !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0 0 8px 0 !important ;
    padding: 10px 20px !important;
    opacity: 0.6;
    &.fc-button-active,&:is(:active,:focus,:hover){
      opacity: 0.8;
      color: @aion-color-white !important;
    }
  }

  .container-calendar{
    height: 100%;
  }
