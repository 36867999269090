@import "../../global.less";
.container-Steps {
  flex: 1 1 100%;
  max-width: 900px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  .container-steps-title {
    width: 100%;
  }
  .container-content {
    flex: 1 1 auto;
    width: 100%;
  }
  .container-actions {
    display: flex;
    width: 100%;
    padding-bottom: 15px;
    > button {
      display: flex;
      align-items: center;
      height: auto;
      padding: 8px 20px;
      &.btn-prev {
        margin-left: 10px;
      }
    }
  }
}
.container-registration{
  flex-direction: row;
  gap: 0px;
  height: 100vh;
  overflow: auto;
  .content-intent-configuration{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .container-inputs{
      display: flex;
      flex-direction: column;
      width: 400px;
    }
  }
  .wrapper-pricing-cards{
    height: 80vh;
    overflow: auto;
    padding: 10px;
  }
}

.container-custom-step{
  .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon{
    width: 12px;
    height: 12px;
  }

  .ant-steps-item-content{
    width: unset;
  }
}

.container-intent-configuration,.container-payments-information{
  height: 100%;
  display: flex;
  flex-direction: column;
  .btn-next{
    align-self: flex-end;
  }
}

.container-setup-bg{
  flex: 1 1 100%;
  background-image: url('../../../public/img/background/aion-setup-bg.svg') ;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: @aion-color-blue;
  height: 100%;
  width: 100%;
}
.custom-label-style{
  .ant-col.ant-form-item-label >label{
    font-size: @aion-font-size-xl;
    padding-bottom: 8px;
  }
}
.container-payments-information{
  align-items: center;
  justify-content: center;
  >div{
    height: fit-content;
    .title-page-payment{
      text-align: center;
      font-size: @aion-font-size-6x;
      color: @aion-color-gray;
    }
  }
}
.title-additional-information{
  text-align: center;
  font-size: @aion-font-size-6x;
  color: @aion-color-gray;
  margin-bottom: 20px;
}

.btn-logout{
  align-self: start;
  margin-left: 5px;
  margin-top: 5px;
  border: 1px solid @aion-color-blue;
  color:@aion-color-blue;
}

.custom-label-style.domain-name{
.ant-input-group-addon,.ant-input-affix-wrapper-lg{
  height: 43px;
  border: none;
  border-radius: @aion-border-radius-md;
}
}
