
@import "../../global.less";
.container-menu-dropdown-toggle,
.container-menu-dropdown-toggle:is(:hover,:active,:focus) {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  .aion-notification-icon,
  .aion-profile-icon {
    height: 30px;
    width: 30px;
    path {
      fill: @aion-color-blue;
    }
  }
}

.ant-dropdown {
  .ant-dropdown-menu {
    border-radius: 8px;
    padding: 0;
    li.ant-dropdown-menu-item {
      padding: 8px 12px;
    }
  }
}

.btn-back {
  display: flex;
  color: @aion-color-blue;
  border: none;
  align-items: center;
  svg{
    height: 22px;
    width: 22px;
    path{
      fill: @aion-color-blue;
    }
  }
  span.keyboard-arrow-left-icon svg {
    color: @aion-color-blue;
    fill: @aion-color-blue;
  }
}
.container-dashboard{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100%
}
.content-dashboard{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.empty-notifications{
  min-height: 80px;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.notification-item{
  max-width: 350px;
  max-height: 90px;
  overflow-y: hidden;
  .name-user{
    font-size: @aion-font-size-lg;
    font-weight: @aion-font-weight-bolder;
    text-transform: capitalize;
  }
  .email-user,.message-user{
    font-size: @aion-font-size-md;
    color: @aion-color-dark-silver;
  }
  .email-user{
    font-weight: @aion-font-weight-bold;
  }
}
.main-container-dashboard{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:100%;
  padding: 50px;
  width: 100%;
  h2{
    font-size: @aion-font-size-6x;
    font-weight: @aion-font-weight-bolder;
    color: @aion-color-gray
  }
  .chart-line-conversation{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 8px;
    padding: 8px;
    max-height: 500px;
    min-width: 800px;
  }
}
.container-custom-step.small-steps{
  display: flex;
  justify-content: center;
  align-items: center;
  >div{
    max-width: 300px;
    transform: translateX(-40px);
  }
}

.modal-dashboard{
  width: 650px !important;
  .ant-modal-body{
    min-height: 300px;
    padding-top: 15px;
  }
.modal-guide-install{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center ;
}
}

.header-page{
  text-align: center;
  font-size: @aion-font-size-6x;
  font-weight: @aion-font-weight-bold;
}
