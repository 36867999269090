@import "../../../global.less";

.custom-input-primary{
    background: @aion-color-white;
    border-radius: @aion-border-radius-md;
    border: none;
    box-shadow:rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;;
    .aion-inter-medium(@aion-font-size-3x,@aion-line-height-md);
    color:@aion-color-semi-dark;
    .ant-input{
        background:  @aion-color-white;
        color:@aion-color-semi-dark;
        &::placeholder{
            color: @aion-color-semi-dark ;
        }
    }
   
    &:focus{
        box-shadow: none;
    }
    .ant-input-prefix{
        margin-right: 10px;
    } 
    &:disabled{
        background: #EDEDED;
    }
}

.custom-input-secondary{
    background: @aion-color-white;
    border-radius: @aion-border-radius-sm;
    border: 1px solid @aion-color-blue;
    height: 45px;
    box-shadow: none;
    .aion-inter-medium(@aion-font-size-xl,@aion-line-height-md);
    color:@aion-color-semi-dark;
    .ant-input{
        background:  @aion-color-white;
        color:@aion-color-dark-silver;
        &::placeholder{
            color:@aion-color-dark-silver ;
            font-size:@aion-font-size-md ;
        }
    }
   
   
    .ant-input-prefix{
        margin-right: 10px;
    } 
    &:disabled{
        background: #EDEDED;
    }
}
