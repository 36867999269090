@import "../../global.less";
.content-chat-bot-setting {
  display: block;
  h1.heading{
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: @aion-font-size-6x;
    font-weight: @aion-font-weight-bold;

  }
  .container-settings{
    display: block;
    height: 100%;
    padding: 80px 50px;
    max-height: min(calc(100vh - 100px), 800px);
    overflow: auto;
    h2.heading{
      font-size: @aion-font-size-4x;
      font-weight: @aion-font-weight-bold;
      margin-bottom: 30px;
    }
  }
  .ant-btn.ant-btn-primary.btn-lg.btn-next {
    align-self: center;
    width: 450px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 12px 0px;
    padding: 10px 20px;
  }
  .container-intent-configuration {
    //max-height: min(calc(100vh - 100px), 800px);
    //overflow: auto;
    width: 70%;
    max-width: 70%;
    align-items: center;
    @media (max-width: 1200px) {
      width: 40%;
      max-width: 40%;
    }
    @media (max-width: 980px) {
      width: 100%;
      max-width: 100%;
    }
    > form {
      min-width: 450px;
    }
  }
}
.preview-section {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    @media (max-width: 980px) {
      position: relative;
    }
    .btn-preview {
      background-color: @aion-color-blue;
      border: none;
      color: @aion-color-white;
      padding: 10px 20px;
      border-radius: @aion-border-radius-md;
      font-size: @aion-font-size-md;
      font-weight: @aion-font-weight-bold;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: @aion-color-dark-blue;
      }
    }
  > h3 {
    font-size: @aion-font-size-4x;
    font-weight: @aion-font-weight-bold;
    text-align: center;
    margin-bottom: 30px;
  }
  .container-preview {
    display: flex;
    align-items: center;
    justify-content: end;
  }
}

.content-preview {
  @chat-primary-color: #06c;
  @chat-secondary-color: red;
  @chat-white-color: #f7f7f7;
  @chat-wheat-color: #ccc;
  @chat-light-color: #fff;
  @chat-input-color: #555;
  @chat-primary-shadow-color: rgba(17, 17, 26, 0.1);
  @chat-secondary-shadow-color: rgba(0, 0, 0, 0.1);
  @chat-tertiary-shadow-color: rgba(0, 0, 0, 0.075);
  @chat-primary-box-shadow: 0 0 2rem @chat-tertiary-shadow-color,
    0rem 1rem 1rem -1rem @chat-secondary-shadow-color;
  @chat-secondary-box-shadow: 0 0 2rem @chat-tertiary-shadow-color,
    0rem 1rem 1rem -1rem @chat-secondary-shadow-color;

  display: flex;
  flex-direction: column;
  border-radius: 12px;

 .card{
   border-radius: 12px;
 }
  .card-chat-box ::-webkit-scrollbar {
    width: 8px;
  }
  .card-chat-box ::-webkit-scrollbar-track {
    background-color: @chat-white-color;
  }
  .card-chat-box ::-webkit-scrollbar-thumb {
    background-color: @chat-primary-color;
  }
  .card-chat-box ::-webkit-scrollbar-thumb:hover {
    background-color: @chat-input-color;
  }
  /* Buttons (optional) */
  .card-chat-box ::-webkit-scrollbar-button {
    display: none;
  }
  .input-message {
    padding: 0.5rem 1rem;
    margin: 0;
    background: transparent;
    border-radius: 0px;
    height: 49px;
    font-size: 1rem;
    line-height: 1.5;
    color: @chat-input-color;
    white-space: nowrap;
    cursor: auto;
    border: none;
    outline: none;
    margin-left: 3px;
  }

  .btn-close {
    outline: none;
    //background: transparent;
    margin-top: 8px;
    margin-left: auto;
    transform: translateX(4px);
    border: none;
    padding: 0px;
    border-radius: 50%;
    cursor: pointer;
    transition: width 200ms ease 0s, height 200ms ease 0s, max-height 200ms ease 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s, opacity 83ms ease-out 0s;
    z-index: 999;
    width: 60px;
    height: 60px;
    background-color: #dddddd;
  }
  .card-chat-box {
    height: min(630px);
    width: 400px;
    box-shadow: rgba(14, 142, 240, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    background-color: @chat-white-color;
    border-radius: 12px;
  }
  .card-chat-box * {
    font-family: Red hat Display, sans-serif;
    font-weight: 400;
    line-height: 1.25em;
    letter-spacing: 0.025em;
  }

  .card-header {
    height: 190px;
    text-align: center;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    img {
      transform: translateX(-1px);
      border-radius: 50%;
      width: 45px;
      height: 45px;
    }
    h2 {
      font-size: 22px;
      margin: 15px 0px 10px 0px;
      font-weight: 600;
    }
    p{
      font-size: 14px;
      font-weight: 400;
      max-width: 70%;
      padding: 15px 0px 15px 0px;
    }
  }
  .chat-message-box {
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    margin: 8px;
    background: @chat-light-color;
    box-shadow: @chat-primary-box-shadow;
    min-height: 2.25rem;
    max-width: 80%;
    width: fit-content;
    border-radius: 1.125rem 1.125rem 1.125rem 0;
  }
  .chat-message-box.sent {
    border-radius: 1.125rem 1.125rem 0 1.125rem;
    background-color: @chat-primary-color;
    margin: 1rem 8px 1rem auto;
    color: @chat-light-color;
  }
  .card-body {
    background: @chat-white-color;
    box-shadow: inset 0 2rem 2rem -2rem rgba(0, 0, 0, 0.05),
      inset 0 -2rem 2rem -2rem rgba(0, 0, 0, 0.05);
    flex-shrink: 2;
    overflow-y: auto;
    scroll-behavior: smooth;
    height: 389px;
  }
  .card-footer {
    display: flex;
    border-top: 1px solid #cccccc;
    .header-page{
      text-align: center;
      font-size: @aion-font-size-6x;
      font-weight: @aion-font-weight-bold;
    }   align-items: center;
  }

  .input-message:is(:hover, :active, :focus) {
    border: 1.5px solid @chat-wheat-color;
    outline: none;
  }
  .btn {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
  }
  .btn.btn-sent {
    width: 50px;
    height: 50px;
  }
  .message-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .typing {
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0rem;
    box-sizing: border-box;
    background: @chat-wheat-color;
    border-radius: 50%;
  }

  .typing-1 {
    animation: typing 2s infinite;
  }
  .typing-2 {
    animation: typing 2s 200ms infinite;
  }
  .typing-3 {
    animation: typing 2s 300ms infinite;
  }

  .animation {
    transition: all 2s ease-in-out;
  }

  .chat-message-box-typing.is-typing {
    display: block;
  }

  @keyframes typing {
    0%,
    75%,
    100% {
      transform: translate(0, 0.25rem) scale(0.9);
      opacity: 0.5;
    }

    25% {
      transform: translate(0, -0.25rem) scale(1);
      opacity: 1;
    }
  }
  .g-element {
    stroke: none;
    stroke-width: 0;
    stroke-dasharray: none;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-miterlimit: 10;
    fill: none;
    fill-rule: nonzero;
    opacity: 1;
  }
  .g-path-class {
    stroke: none;
    stroke-width: 1;
    stroke-dasharray: none;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-miterlimit: 10;
    fill-rule: nonzero;
    opacity: 1;
    transform: rotate(45deg) translate(13px, -30px);
  }
}

.btn-code-sniped {
  margin-top: 15px;
  color: @aion-color-blue;
  border: none;
  display: flex;
  justify-content: center;
}


.container-code-block{
  padding: 20px;
  max-width:700px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;  
  background-color: rgba(0, 0, 0, 0.02) ;
  border-radius: @aion-border-radius-md;
  color: @aion-color-dark-silver;
  font-size: @aion-font-size-md;
 svg{
  height: 20px;
  width: 20px;
  display: block;
  path{
      fill:@aion-color-blue
  }
 }
}

