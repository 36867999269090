@import "../../global.less";

.container-header {
  text-align: center;
  font-size: @aion-font-size-6x;
  font-weight: @aion-font-weight-bold;
}
.content-chat-bot-setting {
  width: 100%;
  display: flex;
  .container-intent-configuration,
  .container-preview {
    flex: 1 1;
  }
}
.container-appointment {
  width: 100%;
  height: 100%;
  padding: 0 50px;
  .container-calendar {
    max-width: 1300px;
    margin: auto;
  }
}

