@import "../../global.less";

.full-container{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container-sign-up{
  flex-direction: row-reverse;
}
.content-sing-up{
  text-align: center;
  font-size: 35px;
  font-weight: 600;
}

.form-sign-up{
  flex: 1 1 50%;
  display: flex;
  padding-top: 90px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 20px;
  .sign-up-inputs{
    width: 400px;
  }
}

.btn-lg{
  display: flex;
  align-items: center;
  height: auto;
  padding: 8px 20px;
}

.btn-sign-up{
  margin-top: 12px;
  width: 100%;
  justify-content: center;
}

.container-bg{
  flex: 1 1 50%;
  background-image: url('../../../public/img/background/aion-sign-up-bg.svg') ;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: @aion-color-blue;
  height: 100%;
    width: 100%;
}
.custom-input-prefix.bg-primary{
  svg{
    height: 18px;
    width: 18px;
    path{
      fill: @aion-color-blue ;
    }
  }
}
