@import "~antd/dist/antd.less";
@import "./global.less";

/*** Font Inter ***/
@font-face {
  font-family: "Inter-SemiBold";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Inter-SemiBold.ttf");
}
@font-face {
  font-family: "Inter-Bold";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Inter-Bold.ttf");
}
@font-face {
  font-family: "Inter-Medium";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Inter-Medium.ttf");
}
@font-face {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Inter-Regular.ttf");
}
/*** Font Montserrat ***/
@font-face {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Montserrat-Medium.ttf");
}
/*** font Saira  ***/
@font-face {
  font-family: "Saira-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Saira-Regular.ttf");
}
@font-face {
  font-family: "Saira-Medium";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Saira-Medium.ttf");
}

// Customize here

@primary-color: @aion-color-blue;
@success-color: @aion-color-blue;

@menu-item-active-bg: @aion-color-red;
@menu-highlight-color: @aion-color-white;

// button
@btn-border-radius-base: @aion-border-radius-md;
@btn-primary-shadow: none;

// Menu
// ---

.container-loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > .loading {
    background: url("../public/aion-log.gif") center/cover no-repeat ;
    height: 150px;
    width: 150px;
  }
}
// Modal
// --
@modal-header-close-padding: 24px;
@modal-base-border-radius: 20px;
@modal-body-padding: 16px 20px 10px;
@modal-header-padding: 24px 20px 0 20px;
@modal-header-border-width: 0;
@modal-header-title-line-height: @aion-line-height-md;
@modal-header-title-font-size: @aion-font-size-3x;
@modal-heading-color: @aion-color-gray;
@modal-close-color: @aion-color-blue;
@modal-footer-bg: transparent;
@modal-footer-border-color-split: @border-color-split;
@modal-footer-border-style: @border-style-base;
@modal-footer-padding-vertical: 10px;
@modal-footer-padding-horizontal: 16px;
@modal-footer-border-width: 0px;
@modal-mask-bg: fade(@black, 60%);
@modal-confirm-body-padding: 16px 20px;
@modal-confirm-title-font-size: @aion-font-size-3x;
.input-label-error{
  color:#f15930
}
