@import '../../../global.less';
.container-message-card {
  position: relative;
  padding:8px 10px;
  cursor: pointer;
  background: @aion-color-Platinum;
  border-radius: 8px;
  margin-left: 8px;
  margin-right: 8px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  .container-header-message-card {
    display: flex;
    justify-content: space-between;
    .message-card-title {
        color: @aion-color-gray;
        font-size: 16px;
    }
    .message-card-time{
        color: @aion-color-dark-silver;
        font-size: 16px;
    }
  }
  .description-message-card {
    color: @aion-color-dark-silver;
    font-size: 16px;
    padding: 0;
    margin: 0;
  }

  &.secondary {
    background: @aion-color-white;
    .message-card-title{
        color: @aion-color-blue;   
   } 
  }
}
.menu-list-chat .container-message-card {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.container-btn-help{
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 99;
  .btn-help{
    border: none;
    svg{
      height: 25px;
      width: 25px;
      path{
        color:@aion-color-light-gray;
      }
    }
    &:hover{
      path{
        color: @aion-color-blue;
      }
    }
    }    
}
.popup-help{
  .ant-popover-inner{
    .ant-popover-inner-content{
      color: @aion-color-dark-silver;
    }
  }
}
