@import "../../global.less";
.aion-guiding-user-container{
    background: @aion-color-white;
    margin-left: 15px;
    padding: 16px;
    border-radius: 8px;
    z-index: 100;
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px ;
    outline: none;
    max-width: 650px;
    .shepherd-content{
        .shepherd-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .shepherd-title{
                font-size: @aion-font-size-2x;
                font-weight:@aion-font-weight-bolder;
                color:@aion-color-gray ;
            }
            .shepherd-cancel-icon{
                border: none;
                background: transparent;
                outline: none;
                font-size: @aion-font-size-3x;
                font-weight:@aion-font-weight-bold ;
                color:@aion-color-gray ;
                cursor: pointer;
            }
        }
        .shepherd-text{
            font-size: @aion-font-size-md;
            color:@aion-color-dark-silver;
        }
        .shepherd-footer{
            margin-top: 15px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap:10px;
            >button{
                background-color: @aion-color-blue;
                font-size: @aion-font-size-md;
                padding: 8px 16px;
                min-width: 90px;
                border-radius: @aion-border-radius-md;
                border: none;
                color: @aion-color-Platinum;
                &.button-secondary{
                background-color: @aion-color-dark-silver;
                }
            }
        }
    
    }
}
